.item_size {
  width: 8%;
}

.see_more_w {
  width: 5%;
}

.bg_blue_modal {
  background-color: #155E75;
}

.modal_body {
  max-height: 70vh;
}

#admin_filter {
  font-size: small;
}

::-webkit-scrollbar {
  width: 0px;
}

::-webkit-scrollbar-track {
  display: none;
}