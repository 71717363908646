@tailwind base;
@tailwind components;
@tailwind utilities;

.rubik-font {
  font-family: 'Rubik',
    Arial,
    Helvetica,
    sans-serif;
}

.inter-font {
  font-family: 'Inter', Arial, Helvetica, sans-serif;
}

.mono-font {
  font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
}

.gradient-text {
  background: linear-gradient(110deg, #F70063 10%, #1E4FFF 110%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}

.no-scrollbar {
  -ms-overflow-style: none;
  /* Internet Explorer 10+ */
  scrollbar-width: none;
  /* Firefox */
}

.no-scrollbar::-webkit-scrollbar {
  display: none;
  /* Safari and Chrome */
}

html {
  scroll-behavior: smooth;
}

.stroke-text {
  text-shadow:
    -1px -1px 0 #F70063,
    1px -1px 0 #F70063,
    -1px 1px 0 #F70063,
    1px 1px 0 #F70063;
}

::-moz-selection {
  background: #F70063;
}

::selection {
  background: #F70063;
  color: black;
}